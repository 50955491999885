





























































import { Component, Vue, Prop } from 'vue-property-decorator'
import * as actionTypes from '@/store/actionTypes'
import AuthScreen from './components/AuthScreen.vue'
import ModalHeader from './components/ModalHeader.vue'

@Component({
  name: 'ChangePassword',
  components: {
    ModalHeader,
    AuthScreen,
    PasswordStep: () => import('./components/PasswordStep.vue'),
    SuccessStep: () => import('./components/SuccessStep.vue'),
  },
})
export default class ChangePassword extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly isOpened!: boolean

  isLoggedIn = false

  loading = false

  pass = ''

  newPass = ''
  repeatPass = ''
  newHint = ''
  passErrLabel = ''
  passErr = false

  changePassSuccess = false

  get header () {
    if (this.changePassSuccess) return this.$t('auth.twoFactorAuth.passChanged')
    return this.isLoggedIn
      ? this.$t('auth.twoFactorAuth.changePass')
      : this.$t('auth.twoFactorAuth.title')
  }

  get btnLabel () {
    return this.changePassSuccess
      ? this.$t('auth.twoFactorAuth.backToSettings')
      : this.$t('common.change')
  }

  get btnDisable () {
    return !this.changePassSuccess && !this.isPassValid
  }

  get isPassValid () {
    return this.newPass.length > 0 && this.repeatPass.length > 0
  }

  get btnAction () {
    return this.changePassSuccess ? this.close : this.changePass
  }

  onNewPassInput (value: string) {
    this.newPass = value
    this.passErr = false
    this.passErrLabel = ''
  }

  onNewRepeatInput (value: string) {
    this.repeatPass = value
  }

  onNewHintInput (value: string) {
    this.newHint = value && value.trim()
  }

  // Close modal
  close () {
    this.$emit('close')
  }

  /**
   * * Emitted by AuthScreen
   */
  auth (pass: string) {
    this.isLoggedIn = true
    this.pass = pass
  }

  /**
   * Try to change pass
   * If success - set changePassSuccess = true
   * If error - parse it for old and new password errors
   *  *if old - set login false, label for it and go auth again
   *  *if new - set error truem label for it and input newPass, repeatPass again
   */
  async changePass (): Promise<void> {
    if (!this.isPassValid) return
    this.loading = true

    try {
      await this.$store.dispatch(actionTypes.TFA_CHANGE_PASS, {
        newPass: this.newPass,
        repeatPass: this.repeatPass,
        hint: this.newHint,
        oldPass: this.pass,
      })
      this.changePassSuccess = true
    } catch (e) {
      if (e.details?.new_password) {
        this.passErr = true
        this.passErrLabel = e.details.new_password
      } else if (e.details?.old_password) {
        this.isLoggedIn = false
      } else {
        this.passErrLabel = e.error
      }
    }
    this.loading = false
  }
}
